@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

body {
	--type-first: "Montserrat", sans-serif;
	--type-second: "Poiret One", cursive;
	--type-third: 'Questrial', sans-serif;
	--c-black: #000000;
	--c-white: #ffffff;
	--c-yellow: #c9b87d;
	--c-yellow-light: #fff8df;
	--c-yellow-more-light: #fff3ca;
	--c-yellow-dark: #bda85e;
	--c-brown: #512211;
	font-family: var(--type-first);
	font-weight: 400;
}

* {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

li {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

a {
	text-decoration: none;
	color: var(--c-brown);
}

a:visited {
	color: var(--c-brown);
}

.swiper-slide-shadow-right {
	background-image: none !important;
}

.swiper-slide-shadow-left {
	background-image: none !important;
}

.swiper-slide-visible{
	@media (min-width: 768px) {
		display: block !important;
		margin: auto !important
	}
}


/* a:hover {
	color: var(--c-yellow);
}

*/
/* .active {
	color: #512211;
}

*/